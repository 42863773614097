import React, {Component} from 'react';
import _ from 'lodash';
import moment from 'moment';

import Calendario from './../Calendario'
import SelectMask from './../SelectMask'
import Counter from './../Counter'
import Button from './../Button'
import Popup from './../Popup'
import IconText from '../../components/IconText';
import style from './style.module.css';
import {__} from '../../utils/translator';
import {parseQuery} from './../../utils';
import Select2 from '../../components/Select2';

export default class SearchBar extends Component {

  constructor(props) {
    super(props);

    const isMobile = window.innerWidth < 901 ? true : false;
    this.state = {
      popupOspiti: false,
      topFixedBar: false,
      popupRiepilogo: false,
      bottomFixedBar: this.props.detailPage,
      searchFieldsOpen: !isMobile,
      residences: [],
      ...props
    }

    this.search = this.search.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.searchFields = this.searchFields.bind(this);
    this.onTogglePopup = this.onTogglePopup.bind(this);
    this.onCountChange = this.onCountChange.bind(this);
    this.riepilogoTopBar = this.riepilogoTopBar.bind(this);
    this.onToggleRiepilogo = this.onToggleRiepilogo.bind(this);
    this.riepilogoBottomBar = this.riepilogoBottomBar.bind(this);
    this.verificaDisponibilita = this.verificaDisponibilita.bind(this);
    this.mainBarScrollHandler = this.mainBarScrollHandler.bind(this);
    this.detailBarScrollHandler = this.detailBarScrollHandler.bind(this);
    this.setRef = this.setRef.bind(this);
    this.gotoPayment = this.gotoPayment.bind(this);
    this.closeForMobile = this.closeForMobile.bind(this);

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {

    const {detailPage = false, location} = this.state;
    const query = parseQuery(location.search) || {};

    this.setState({
      selectedResidenceId: query.residenceId
    })

    const startDate = query.startDate && moment(query.startDate) || null
    const endDate = query.endDate && moment(query.endDate) || null
    const count_persone = parseInt(query.persone) || 2

    const dates = {
      startDate,
      endDate,
      count_persone,
    };

    if (this.bottomBar) {
      this.setState({
        detailBarOffset: document.body.offsetHeight + document.getElementsByTagName('footer')[0].offsetHeight,
        ...dates
      });
    } else {
      this.setState({
        barOffset: this.topBar.getBoundingClientRect().top + (document.body.scrollTop || document.documentElement.scrollTop) || 74,
        ...dates
      });
    }

    if (detailPage && this.bottomBar) {
      window.addEventListener('scroll', this.detailBarScrollHandler, true);

      window.addEventListener('click', this.handleClick);
    } else if (!detailPage) {
      window.addEventListener('scroll', this.mainBarScrollHandler, true);
    }

    if (_.get(this.props.esercizio, 'configurazioni.mostra_residence')) {
      this.fetchResidences()
    }
  }

  fetchResidences = async () => {
    if (this.props.getResidences) {
      const residences = await this.props.getResidences(this.state.esercizio)
      this.setState({
        residences,
        selectedResidence: residences.find(r => r.id == this.state.selectedResidenceId)
      })
    }
  }

  onResidencesChange = (item) => {
    const {residences} = this.state
    let selectedResidence = null
    if (item) {
      residences.forEach((residence) => {
        if (residence.id == item.value) {
          selectedResidence = residence
        }
      })
    }

    this.setState({selectedResidence});
  }

  // agiorna lo stato della componente quando le informazioni passate alla componente variano 
  componentDidUpdate(prevProps, prevState) {
    const {detailPage, position} = this.state;

    let newState = {}

    if (prevProps.location.search !== this.props.location.search) {

      const query = parseQuery(this.props.location.search) || {};
      const startDate = query.startDate && moment(query.startDate) || null
      const endDate = query.endDate && moment(query.endDate) || null
      const count_persone = parseInt(query.persone) || 2

      newState = {
        location: this.props.location,
        startDate,
        endDate,
        count_persone,
      }
    }

    if (detailPage && position === 'bottom') {
      if (prevProps.prenotazione !== this.props.prenotazione) {
        newState = {
          ...newState,
          prenotazione: this.props.prenotazione
        };
      }
      if (prevProps.servizi !== this.props.servizi) {
        newState = {
          ...newState,
          servizi: this.props.servizi
        };
      }
      if (prevProps.hasRiepilogo !== this.props.hasRiepilogo) {
        newState = {
          ...newState,
          hasRiepilogo: this.props.hasRiepilogo
        }
      }
      if (prevProps.hasPayments !== this.props.hasPayments) {
        newState = {
          ...newState,
          hasPayments: this.props.hasPayments
        }
      }
      if (prevProps.hasRette !== this.props.hasRette) {
        newState = {
          ...newState,
          hasRette: this.props.hasRette
        }
      }
      if (prevProps.initialinfoOpen !== this.props.initialinfoOpen) {
        newState = {
          ...newState,
          initialinfoOpen: this.props.initialinfoOpen
        }
      }
      if (prevProps.warningDates !== this.props.warningDates) {
        newState = {
          ...newState,
          warningDates: this.props.warningDates
        }
      }
    }

    if (Object.keys(newState).length > 0) {
      this.setState({...newState});
    }
  }

  // Togliamo il listener quando il componente viene "smontato"
  componentWillUnmount() {
    const {detailPage = false} = this.state;
    if (detailPage && this.bottomBar) {
      window.removeEventListener('scroll', this.detailBarScrollHandler, true);
      window.removeEventListener('click', this.handleClick);
    } else if (!detailPage) {
      window.removeEventListener('scroll', this.mainBarScrollHandler, true);
    }
  }

  handleClick(event) {

    const {hasRette, hasRiepilogo, initialinfoOpen} = this.state;
    if (this.bottomBar && !this.bottomBar.contains(event.target)) {
      if (hasRette || hasRiepilogo) {
        if (!hasRiepilogo)
          this.props.updateSearch({hasRiepilogo: true});

      } else {
        if (!initialinfoOpen)
          this.setState({initialinfoOpen: true});
      }
    }

  }


  mainBarScrollHandler = () => {

    const {barOffset, topFixedBar} = this.state;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    const distance = barOffset - winScroll;
    if (distance <= 0 && !topFixedBar) {
      this.setState({
        topFixedBar: true
      });
    } else if (distance > 0 && topFixedBar) {
      this.setState({
        topFixedBar: false
      });
    }
  }


  detailBarScrollHandler = () => {

    const {detailBarOffset, bottomFixedBar} = this.state;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    const distance = document.body.scrollHeight - winScroll - detailBarOffset;
    if (distance >= 0 && !bottomFixedBar) {
      this.setState({
        bottomFixedBar: true
      });
    } else if (distance < 0 && bottomFixedBar) {
      this.setState({
        bottomFixedBar: false
      });
    }
  }

  setRef(node, riferimento) {
    this[riferimento] = node;
  }

  setPopupButtonRef(node) {
    this.popupButtonRef = node;
  }

  onCountChange(event) {
    const {name, count} = event;
    const state = this.state;
    state[name] = count;
    this.setState(state);
  }

  onTogglePopup() {
    this.setState({
      popupOspiti: !this.state.popupOspiti
    });
  }

  onToggleRiepilogo() {
    this.setState({
      popupRiepilogo: !this.state.popupRiepilogo
    });
  }

  onDateChange({endDate, startDate, showUpdatePrice}) {
    if (showUpdatePrice) {
      this.setState({
        showUpdatePrice: true,
        endDate,
        startDate
      });
    } else {
      this.setState({
        endDate,
        startDate
      });
    }
  }

  closeForMobile() {
    const {titolo, hasRiepilogo, searchFieldsOpen} = this.state;
    if (titolo) {
      this.setState({searchFieldsOpen: !searchFieldsOpen})
    } else {
      this.setState({searchFieldsOpen: !searchFieldsOpen, hasRiepilogo: !hasRiepilogo})
    }
  }

  gotoPayment() {
    if (_.get(this.state, 'prenotazione.activePayment') < 0) {
      window.goto(document.body, (document.getElementById('prenota').offsetTop + 80), 600);
    } else {
      window.goto(document.body, (document.getElementById('completa_prenotazione').offsetTop + 50), 600);
    }
  }

  search() {
    let {endDate, startDate, count_persone, search, location, showUpdatePrice, warningDates, selectedResidence} = this.state;

    if (this.props.onFiltersReset) {
      this.props.onFiltersReset()
    }

    if (startDate && typeof startDate !== 'string') {
      startDate = startDate.format('YYYY-MM-DD');
    }
    if (endDate && typeof endDate !== 'string') {
      endDate = endDate.format('YYYY-MM-DD');
    }

    /* let filtersQuery = getFiltersParamsFromQuery()
    if(filtersQuery.length) {
      filtersQuery = "&" + filtersQuery
    } */

    //siamo nella BottomBAR nella pagina dettaglio : il pulsante cliccato è quello per modificare la ricerca sull'appartamento corrente
    if (this.bottomBar) {

      if (!endDate || !startDate) {
        // servirebbe ad aprire il calendario se mancano le date per la ricerca
        // this.onToggleCalendario();
        // this.setState({focusedInput: 'startDate'});
      } else {
        let newSearch = `?startDate=${startDate}&endDate=${endDate}&persone=${count_persone}`;
        if (this.props.location.search !== newSearch) {
          // this.setState({hasRiepilogo: true});
          this.props.updateSearch({isLoading: true, prenotazione: null, warningDates, hasRiepilogo: true});
          this.props.history.push(`${location.pathname}${newSearch}`);
        }
      }
    } else {
      //siamo nella TopBAR : la ricerca è su tutti gli appartamenti dell'agenzia
      if (!endDate || !startDate) {
        // servirebbe ad aprire il calendario se mancano le date per la ricerca
        // this.onToggleCalendario();
        this.setState({focusedInput: 'startDate'});
      } else {
        if (search) {
          let url = `/search?startDate=${startDate}&endDate=${endDate}&persone=${count_persone}`
          if (selectedResidence) {
            url += `&residenceId=${selectedResidence.id}`
          }

          this.props.history.push(url);
        } else {
          let url = `/search?startDate=${startDate}&endDate=${endDate}&persone=${count_persone}`
          if (selectedResidence) {
            url += `&residenceId=${selectedResidence.id}`
          }
          window.location.assign(`${window.basename}${url}`);
        }
        this.setState({hasRiepilogo: true});
      }
    }

  }

  render() {

    const {warningDates, titolo, hasRiepilogo = false, initialinfoOpen = false, detailPage = false, homePage = false, searchPage = false, position = 'top', bottomFixedBar, topFixedBar} = this.state;
    const searchOpenClass = [style.searchOpen].join(' ');
    const riepilogoOpenClass = style.riepilogoOpen;
    const initialinfoOpenClass = style.initialinfoOpen;

    if (detailPage && position === 'bottom') {
      return (
        <div ref={e => this.setRef(e, 'bottomBar')} className={[
          style.searchBar,
          style[position],
          position,
          bottomFixedBar ? 'fixed_bar' : '',
          hasRiepilogo ? riepilogoOpenClass : initialinfoOpen ? initialinfoOpenClass : searchOpenClass,
          'secondary-background',
          'secondary-color'
        ].join(' ')}>

          {warningDates &&
            <div className={[style.warningMessage, 'primary-background'].join(' ')}>
              <div className={style.warningMessageInner}>
                <p className={'primary-color'}>{__('Le informazioni utilizzate per la ricerca non sono corrette.')} <span onClick={() => this.setState({hasRiepilogo: !hasRiepilogo})}
                                                                                                                          className={style.link}>{__('Verifica la disponibilità dell\'appartamento')}</span> <i
                  className={['icon-close', style.close_icon, style.icon].join(' ')} onClick={() => this.setState({warningDates: false})}></i></p>
              </div>
            </div>
          }
          {this.verificaDisponibilita()}
          {this.riepilogoBottomBar()}
          {this.searchFields('up')}
        </div>
      )
    }
    ;
    let padding = '0';
    if (homePage) {
      padding = '0';
    } else if (titolo) {
      padding = '130';
    } else if (!detailPage) {
      if (hasRiepilogo) {
        padding = '60';
      } else {
        padding = '100';
      }
    }

    return (
      <div ref={e => this.setRef(e, 'topBar')}
           className={[
             style.searchBar,
             style[`padding_${padding}`],
             (topFixedBar && !homePage) ? 'fixed_bar' : '',
             (detailPage || homePage || searchPage) ? style.relative : '',
             position,
             hasRiepilogo ? riepilogoOpenClass : searchOpenClass,
             'searchbar-background',
             'searchbar-color'].join(' ')}>

        {titolo && <h2 className={[style.label, style.titolo, 'title-color'].join(' ')}>{titolo}</h2>}
        {this.riepilogoTopBar()}
        {this.searchFields('down')}

        <div className={[style.buttonOpenFields, 'searchbar-background', 'searchbar-color', 'searchFieldsButton'].join(' ')} onClick={this.closeForMobile}></div>
      </div>
    )
  }


  /**
   * campi di ricerca comuni ad entrambe le barre
   * @param {*} direction
   */
  searchFields(direction = 'down') {

    const {esercizio, titolo, startDate, endDate, popupOspiti, detailPage, hasRiepilogo, searchFieldsOpen, count_persone} = this.state;
    const diff = startDate && endDate && endDate.diff(startDate, 'days') || ' ';
    let openClass = ''
    if (!searchFieldsOpen && titolo) {
      openClass = 'h_0';
    } else if (hasRiepilogo) {
      openClass = 'h_0';
    }

    const residencesOptions = this.state.residences.map((item) => {
      return {
        value: item.id,
        label: item.nome
      }
    })

    const showResidences = _.get(esercizio, "configurazioni.mostra_residence")

    return <div ref={e => this.setRef(e, 'searchFieldsRef')} className={[style.searchFields, style.searchForm, openClass].join(' ')}>
      <div className={style.inner}>
        <div id={this.props.calendario ? 'bot_bar_calendar' : 'top_bar_calendar'} className={[style.calendarioContainer, (detailPage && direction === 'up') ? 'secondary-background secondary-color' : ''].join(' ')}>
          {this.props.calendario ?
            <Calendario
              key={'calendar_bottom'}
              maintainOpen={false}
              openDirection={'up'}
              position={'bottom'}
              esercizio={esercizio}
              endDate={endDate}
              startDate={startDate}
              updateDates={this.onDateChange}
              match={this.state.match}
              location={this.state.location}
              minimumNights={this.state.minimumNights}
            /> :
            <Calendario
              position={'top'}
              key={'calendar_top'}
              endDate={endDate}
              startDate={startDate}
              esercizio={esercizio}
              openDirection={direction}
              position={this.props.position}
              updateDates={this.onDateChange}
              location={this.state.location}
              match={this.state.match}/>
          }
          {/* <div className={[style.nottiContainer].join(' ')}>          CAMPO NOTTI NELLA BARRA DI RICERCA DISABILITATO
            <div className={[style.label,'title-color'].join(' ')} >{__('Notti:')}</div>
            <div className={[style.nottiInput,(detailPage && direction === 'up') ? 'a' : 'searchbar-background searchbar-color' ].join(' ')}>{diff}</div>
          </div> */}
        </div>

        <div className={style.ospiti}>
          <Popup
            direction={this.topBar ? 'top' : 'bottom'}
            toggle={popupOspiti}
            onToggle={this.onTogglePopup}
            button={<SelectMask
              onClick={this.onTogglePopup}
              label={__('Ospiti')}
              text={__('%s persona|%s persone', count_persone)}
              icon={'icon-doblearr'}
              className={(
                detailPage && direction === 'up') ?
                'secondary-background secondary-color' : 'searchbar-background searchbar-color'}
            />
            }>
            <Counter
              onCountChange={this.onCountChange}
              label='persona|persone'
              name='count_persone'
              count={count_persone}
              maxValue={_.get(esercizio, 'configurazioni.ospiti_massimi', 20)}
            />
          </Popup>

          {!detailPage && showResidences && <div className={style.hideMobile}>
            <div className={['title-color'].join(' ')}>{__("Residence")}</div>
            <Select2
              containerStyle={{
                marginTop: 5,
                marginRight: 10,
                zIndex: 200,
              }}
              className="residence-select"
              value={this.state.selectedResidence ? {
                value: this.state.selectedResidence.id,
                label: this.state.selectedResidence.nome
              } : undefined}
              placeholder={__("Seleziona residence")}
              onChange={this.onResidencesChange}
              options={residencesOptions || []}
            />
          </div>}

          <Button
            onClick={this.search}
            label={'cerca'}
            bgColor={'primary-background'}
            active={(!endDate || !startDate) ? false : true}/>

        </div>
      </div>
    </div>;
  }

  /**
   * Riepilogo delle informazioni per la ricerca globale (top bar)
   */
  riepilogoTopBar() {
    const {startDate, endDate, count_persone, hasRiepilogo, search, searchFieldsOpen} = this.state;
    const startFormatted = startDate && startDate.format('DD/MM/YYYY') || null;
    const endFormatted = endDate && endDate.format('DD/MM/YYYY') || null;
    const diff = startDate && endDate && endDate.diff(startDate, 'days') || ' ';

    return <div ref={e => this.setRef(e, 'riepilogoTopRef')} className={[style.searchInfos, 'font_300'].join(' ')}>
      <p onClick={() => this.setState({hasRiepilogo: !hasRiepilogo})}
         className={'searchbar-color'}>
        {__('Arrivo')} <b>{startFormatted}</b>, {__('partenza')} <b>{endFormatted}</b> - <span className={style.nowrap}>{__('%s notte|%s notti', diff)}</span> - <span className={style.nowrap}>{__('%s persona|%s persone', count_persone)}</span>
      </p>
      {search ?
        <Button
          onClick={() => this.setState({searchFieldsOpen: !searchFieldsOpen, hasRiepilogo: !hasRiepilogo})}
          label={'Modifica Ricerca'}
          bgColor={'primary-background'}
          width={'auto'}/>
        :
        <Button
          href={`${window.basename}/search?startDate=${startDate && startDate.format('YYYY-MM-DD')}&endDate=${endDate && endDate.format('YYYY-MM-DD')}&persone=${count_persone}`}
          label={'Torna alla ricerca'}
          bgColor={'primary-background'}
          width={'auto'}/>
      }
    </div>;
  }

  /**
   * Informazioni iniziali sull'appartamenti + CTA per verificare la disponibilità mostrando i campi di ricerca (bottom bar - pagina dettaglio)
   */
  verificaDisponibilita() {
    const {initialinfoOpen} = this.state;
    return <div ref={e => this.setRef(e, 'initialInfoRef')} className={[style.verificaDisponibilita, ''].join(' ')}>
      <div className={[style.w_66, style.left_block].join(' ')}>
        <div className={style.title}>
          <h3 className={['searchbar-color', 'font_400'].join(' ')}>{__('da_euro')} <span className={style.price}>€ 50,00</span> <span className={style.nowrap}>{__('per notte')}</span></h3>
        </div>
        <div className={style.verificaCTA} onClick={() => this.setState({initialinfoOpen: !initialinfoOpen})}>
          <div className={style.innerCTA}>
            <IconText
              className='primary-color'
              font_weight='400'
              text={__('Verifica Disponibilità')}
              icon={'icon-calendar'}/>
          </div>
        </div>
      </div>
      <div className={[style.w_33, 'align_right'].join(' ')}>
        <Button onClick={() => window.goto(document.body, document.getElementById('contatti').offsetTop, 600)} label={'Richiedi informazioni'} bgColor={'primary-background'} width={'auto'}/>
      </div>
    </div>;
  }

  /**
   * Riepilogo delle informazioni per la verifica disponibilità dell'appartamento (bottom bar - pagina dettaglio)
   * @param {*} startDate
   * @param {*} endDate
   * @param {*} persone
   * @param {*} hasRiepilogo
   */
  riepilogoBottomBar() {
    const {popupRiepilogo, startDate, endDate, count_persone = 2, hasRiepilogo = false, prenotazione, servizi, appartmentName, appartmentType, hasPayments} = this.state;
    const numero_notti = startDate && endDate && endDate.diff(startDate, 'days') || ' ';
    let hasServizi = false;
    let prezzoListino = 0;
    let prezzoScontato = 0;
    let prezzoPulizie = 0;
    let prezzoConPulizie = 0;
    let prezzoFinale = 0;
    let retta = [];

    if (_.get(prenotazione, 'activePayment', -1) === -1 && _.get(prenotazione, 'min_price')) {
      prezzoFinale = __('da_euro') + ' € ' + parseFloat(_.get(prenotazione, 'min_price')).toFixed(2);
    } else {
      retta = _.get(prenotazione, 'retta', false);
      if (retta) {
        if (servizi && Object.values(servizi).length > 0) {
          Object.values(servizi).map((val, indx) => {
            if (val.isAggiunto) {
              hasServizi = true;
              prezzoFinale += val.prezzo_servizio;
            }
          })
        }
        if (_.get(window, 'esercizio.configurazioni.prezziConDettaglioPulizie') && retta.offerte && Object.values(retta.offerte).length > 0) {
          Object.values(retta.offerte).map((val, indx) => {
            if (val.tags && Object.values(val.tags).includes('pulizie')) {
              prezzoPulizie += val.variazione;
            }
          })
        }
        prezzoConPulizie = retta.prezzo;
        prezzoListino = retta.prezzo_base - prezzoPulizie;
        if (retta.prezzo < retta.prezzo_base) {
          prezzoScontato = retta.prezzo - prezzoPulizie;
        }
        prezzoFinale = '€ ' + parseFloat(prezzoFinale + _.get(prenotazione, 'retta.prezzo')).toFixed(2);
      } else {
        prezzoFinale = <span className={style.smalltext}>{__('prezzi non disponibili')}</span>;
      }
    }

    return <div ref={e => this.setRef(e, 'riepilogoBottomRef')} className={[style.searchInfos, 'font_300'].join(' ')}>
      <div className={[style.left_block, style.searchInfoInner].join(' ')}>
        <div className={[style.dettagli].join(' ')}>
          <div className={['relative', style.bottom_riepilogoFlex].join(' ')} onClick={() => { /*this.setState({hasRiepilogo: !hasRiepilogo});*/
            this.props.updateSearch({hasRiepilogo: !hasRiepilogo});
          }}>
            <div>
              {appartmentName &&
                <h4>{appartmentName}</h4>
              }
            </div>
            <div className={'align_right'}>
              <p className={'secondary-color'}>
                {__('Arrivo')} <b>{startDate && startDate.format('DD/MM/YYYY')}</b><br/>
                {__('Partenza')} <b>{endDate && endDate.format('DD/MM/YYYY')}</b>
              </p>
              <p className={['searchbar-color', style.inline_block].join(' ')}><span className={style.nowrap}>{__('%s notte|%s notti', numero_notti)}</span> - <span className={style.nowrap}>{__('%s persona|%s persone', count_persone)}</span></p>
            </div>
          </div>
        </div>
        <div className={[style.prezzi].join(' ')}>
          <h3 className={['searchbar-color', 'font_400', style.nowrap].join(' ')}><span className={style.price}>{prezzoFinale}</span></h3>
          <div className={style.prezziCTA}>
            <Popup direction={'bottom'} toggle={popupRiepilogo} onToggle={this.onToggleRiepilogo} classe={'prezzi'}
                   button={<IconText onClick={this.onToggleRiepilogo} className={['title-color', style.pad_0].join(' ')} font_weight='400' text={__('Dettaglio Prezzi')} icon={'icon-prices'}/>}>
              {<div className={['c', style.prezzoContainer].join(' ')}>
                <span><h4>{appartmentType}</h4></span> <span><b>{appartmentName}</b></span>
              </div>}
              {<div className='a'>
                <h4>{__('Riepilogo prenotazione')}</h4>
                <p><span>{__('Dal')}</span> <span>{startDate && startDate.format('DD/MM/YYYY')}</span></p>
                <p><span>{__('Al')}</span> <span>{endDate && endDate.format('DD/MM/YYYY')}</span></p>
                <p><span>{__('Notte|Notti', numero_notti)}</span> <span>{numero_notti}</span></p>
                <p><span>{__('Ospiti')}</span> <span>{__('%s persona|%s persone', count_persone)}</span></p>
              </div>}
              {(retta && Object.values(retta).length > 0) &&
                <div className={['b'].join(' ')}>
                  {(prezzoScontato > 0) &&
                    <div>
                      <p><span><b>{__('Prezzo di listino:')}</b></span>
                        <span><span className='slashed'>€ {parseFloat(prezzoListino).toFixed(2)}</span></span>
                      </p>
                      <p><span><b>{__('Prezzo scontato:')}</b></span>
                        <span>€ {parseFloat(prezzoScontato).toFixed(2)}</span></p>
                    </div>
                  }
                  {(prezzoPulizie > 0 && prezzoScontato <= 0) &&
                    <p><span><b>{__('Prezzo di listino:')}</b></span>
                      <span>€ {parseFloat(prezzoListino).toFixed(2)}</span></p>
                  }
                  {(prezzoPulizie > 0) &&
                    <p><span><b>{__('Prezzo pulizie:')}</b></span>
                      <span>€ {parseFloat(prezzoPulizie).toFixed(2)}</span></p>
                  }
                  <p><span><b>{__('Prezzo:')}</b></span> <span>€ {parseFloat(prezzoConPulizie).toFixed(2)}</span></p>
                </div>
              }
              {(hasServizi && servizi && Object.values(servizi).length > 0) &&
                <div className='b'>
                  <h4>{__('Servizi aggiuntivi')}</h4>
                  {Object.values(servizi).map((val, indx) => {
                    if (val.isAggiunto) {
                      return (
                        <p key={indx}>
                          <span><b>{val.servizio.titolo.testo}:</b><br/>{val.giorni && __('%s giorno|%s giorni', val.selectedGiorni) + ' X ' + __('%s persona|%s persone', val.selectedPersone)}</span>
                          <span>€ {parseFloat(val.prezzo_servizio).toFixed(2)}</span>
                        </p>
                      )
                    }
                  })}
                </div>
              }
              {prezzoFinale &&
                <div className={['c', style.prezzoContainer].join(' ')}>
                  <span><h4>{__('Prezzo Finale:')}</h4></span> <span><b>{prezzoFinale}</b></span>
                </div>
              }
            </Popup>
          </div>
          {hasPayments ? (
            <Button onClick={this.gotoPayment} label={'Avanti'} bgColor={'primary-background'} width={'auto'}/>
          ) : (
            <Button onClick={() => window.goto(document.body, document.getElementById('contatti').offsetTop, 600)} label={'Richiedi informazioni'} bgColor={'primary-background'} width={'auto'}/>
          )}
        </div>
      </div>


    </div>;
  }
}