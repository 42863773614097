import React, {Component, Fragment} from "react";
import _ from 'lodash';

import BookingError from './../../components/Errore';
import OSMap from '../../components/OSMap';
import Gallery from '../../components/Gallery';
import IconText from '../../components/IconText';
import SearchBar from '../../components/SearchBar';
import Appartamenti from '../../services/appartamenti';
import MetodoPagamento from './../../components/ItemPagamento';
import ServiziAggiuntivi from './../../components/ServiziAggiuntivi';
import Counter from '../../components/Counter'
import Popup from '../../components/Popup'
import Form from './../../components/Form';
import styles from './style.module.css';
import {buildQuery, getDistanze, getTitoloAppartamento, parseQuery} from './../../utils';
import {fieldsBooking, fieldsInformation, privacy_fieldsBooking, privacy_fieldsInformation} from './fieldsForm';
import {__} from './../../utils/translator';
import Calendario from "../../components/Calendario";
import moment from 'moment';
import TextInput from "./../../components/TextInput";
import Button from "../../components/Button";
import Panorama from "../../components/Panorama";
import ShowMore from "react-show-more-text";
import Modal from "react-modal";
import Esercizio from "../../services/esercizio";
import style from "../Confirmation/style.module.css";


const DescrizioneOfferta = ({children}) => {
  return (
    <p className={styles.testoOfferte}>
      <ShowMore
        lines={10}
        more={<span className={styles.showMore}>{__("Mostra tutto")}</span>}
        less={<span className={styles.showMore}>{__("Mostra meno")}</span>}
      >
        {children.split('\n').map((item, key) => {
          return <>{item}<br/></>
        })}
      </ShowMore>
    </p>
  )
}

const Loader = () => <div className={'loader'}>
  <div className='spinner'>
    <div></div>
    <div></div>
  </div>
</div>;

export default class Detail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      location: {search: ''},
      match: {params: {}},
      prenotazione: null,
      servizi_aggiuntivi: null,
      isLoading: false,
      persone: 1,
      calendar: null,
      noAppartamento: false,
      showUpdatePrice: false,
      richiestaInfoChiusa: false,
      hasPayments: false,
      activePayment: false,
      cambiaDate: false,
      discountError: null,
      ...props
    };

    this.oggi = moment();
    this.esercizioSvg = new Esercizio();
    this.appartamenti = new Appartamenti();
    this.fetchData = this.fetchData.bind(this);
    this.onResetForm = this.onResetForm.bind(this);
    this.onContactSubmit = this.onContactSubmit.bind(this);
    this.onPrenotaSubmit = this.onPrenotaSubmit.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onPaymentClick = this.onPaymentClick.bind(this);
    this.onChangeServizi = this.onChangeServizi.bind(this);
    this.getAppartamentoWhitoutDates = this.getAppartamentoWhitoutDates.bind(this);
    this.onUpdatePaymentClick = this.onUpdatePaymentClick.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.discount = ""
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.location.search !== this.props.location.search) {
      this.setState({
        location: Object.assign({}, this.props.location),
      }, () => {
        this.fetchData();
      });
    }


    if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate || prevState.showUpdatePrice !== this.state.showUpdatePrice) {

      let newState = {};
      if (prevProps.startDate !== this.props.startDate) {
        newState = {
          startDate: this.props.startDate
        }
      }
      if (prevProps.endDate !== this.props.endDate) {
        newState = {
          ...newState,
          endDate: this.props.endDate
        }
      }
      if (prevState.showUpdatePrice !== this.state.showUpdatePrice) {
        newState = {
          ...newState,
          showUpdatePrice: this.props.showUpdatePrice
        }
        this.onUpdatePaymentClick();
      }

      if (Object.keys(newState).length > 0) {
        this.setState({...newState});
      }
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  onDateChange(event) {
    const {endDate, startDate, showUpdatePrice, focusedInput} = event;

    if (showUpdatePrice) {
      if (startDate && endDate && focusedInput === 'endDate') {
        this.setState({
          showUpdatePrice: true,
          startDate,
          endDate
        });
      } else {
        this.setState({
          startDate,
          endDate
        });
      }
    } else {
      this.setState({
        endDate,
        startDate
      });
    }
  }


  fetchData() {

    const self = this;
    const {
      esercizio,
      location: {search},
      match: {
        params: {detail}
      }
    } = this.state;

    if (!esercizio) return;

    const query = parseQuery(search, this.normalizeParams);

    if (query.payment_error) {
      delete query.payment_error
      let nextQuery = ""
      if (Object.keys(query).length > 0) {
        nextQuery = "?" + buildQuery(query)
      }
      window.history.pushState({}, null, window.location.origin + window.location.pathname + nextQuery)
      this.setState({
        paymentErrorModal: true
      })
    }

    if (query.startDate && query.endDate && query.persone) {

      const startDate = moment(query.startDate);
      const endDate = moment(query.endDate);
      const count_persone = parseInt(query.persone) || 2;

      //reset startdate in base a prenotazione in giornata
      if (esercizio.configurazioni.prenotazione_giornata_finoalle) {
        startDate.startOf('day').add(esercizio.configurazioni.prenotazione_giornata_finoalle, 'hours');
      }

      if (startDate < moment() || endDate < moment() || endDate < startDate) {
        this.getAppartamentoWhitoutDates(esercizio, detail, true);
      } else {
        query.appartamento = detail;
        this.appartamenti.searchAppartamenti(esercizio, 1, 0, 0, null, null, query)
          .then(({items}) => {
            if (items && items.length > 0) {
              const pageContent = items[0];
              const {residence: {distanze}} = pageContent;
              pageContent.residence['distanze'] = getDistanze(distanze);

              let activePayment = self.state.activePayment
              let discountValid = false
              let discountInvalid = false //Uso due variabili invece di una sola per distinguere anche il caso in cui lo sconto non sia inserito
              const rette = _.get(pageContent, 'rette', null);
              if (rette && rette.length > 0) {
                if (!activePayment || !rette.find((retta) => retta.pagamento && retta.pagamento.id === activePayment)) {
                  rette.every((retta) => {
                    if (retta.pagamento) {
                      activePayment = retta.pagamento.id
                      return false
                    }
                    return true
                  })
                }

                if (query.discount) {
                  this.discount = query.discount
                  rette.forEach((retta) => {
                    const offerte = retta.offerte
                    Object.values(offerte).forEach((offerta) => {
                      if (offerta.buono_sconto && offerta.buono_sconto === query.discount) {
                        discountValid = true
                      }
                    })
                  })
                }
              }
              const hasPayments = activePayment !== false;

              if (!discountValid && query.discount) {
                discountInvalid = true
              }

              self.setState({
                persone: count_persone,
                count_persone: count_persone,
                discount: query.discount,
                warningDates: false,
                detailBar: query,
                activePayment,
                pageContent,
                startDate,
                endDate,
                query,
                showUpdatePrice: false,
                isLoading: false,
                hasRiepilogo: true,
                richiestaInfoChiusa: hasPayments,
                hasPayments: hasPayments,
                discountValid,
                discountInvalid,
                discountError: discountInvalid ? __("Codice sconto non valido") : null
              });
              /* effettuo la chiamata per recuperare le informazioni dell'offerta (se presente)
              if(_.get(pageContent,'rette[0].offerte',false)){
                self.getInfoOfferta();
              }
              */
            } else {
              self.getAppartamentoWhitoutDates(esercizio, detail, true);
            }
          })
          .catch(error => console.log(error));
      }

    } else {
      this.getAppartamentoWhitoutDates(esercizio, detail, false);
    }
  }

  getAppartamentoWhitoutDates(esercizio, detail, warning) {

    const self = this;
    this.appartamenti.getAppartamento(esercizio, detail)
      .then(({data}) => {

        if (Object.keys(data).length === 0 || data.error) {
          console.log('data.error');
          return self.setState({
            isLoading: false,
            noAppartamento: true
          });
        }

        let pageContent = Object.values(data)[0];
        const {residence: {distanze}} = pageContent;
        pageContent.residence['distanze'] = getDistanze(distanze);

        const state = {
          pageContent,
          startDate: null,
          endDate: null
        }

        if (warning) {
          state.richiestaInfoChiusa = false;
          state.hasPayments = false;
          state.warningDates = true;
          state.hasRiepilogo = true;
          state.hasRiepilogo = true;
        }

        self.setState({
          showUpdatePrice: false,
          isLoading: false,
          ...state
        });
      })
      .catch((error) => console.log(error));
  }

  onChangeServizi(servizi_aggiuntivi) {
    this.setState({servizi_aggiuntivi: servizi_aggiuntivi});
  }

  onSearch(filters) {
    filters = this.normalizeParams(filters)
    const self = this;
    const {esercizio} = this.state;
    this.appartamenti.searchAppartamenti(esercizio, 10, 0, 0, filters)
      .then(({items}) => {
        self.setState({items});
      })
      .catch(error => console.log(error));
  }

  onPaymentClick({info, frasiPenali, activePayment, retta, info_offerte}) {
    this.setState({
      activePayment,
      prenotazione: {retta, info, frasiPenali, activePayment, info_offerte},
    });
  }

  onPaymentLoaded = (id, payment) => {
    if (id === this.state.activePayment) {
      this.onPaymentClick(payment)
    }
  }

  onContactSubmit(body, errors) {
    const {esercizio, query, pageContent, prenotazione} = this.state;
    const self = this;
    const tariffa = _.get(prenotazione, 'retta.tariffa', false) ? _.get(prenotazione, 'retta.tariffa') : _.get(pageContent, 'rette[0].tariffa', false)
    const prezzo = _.get(prenotazione, 'retta.prezzo', false) ? _.get(prenotazione, 'retta.prezzo') : _.get(pageContent, 'rette[0].prezzo', false)

    if (esercizio) {

      this.setState({isLoading: true});
      this.appartamenti.postRichiestaInformazioni(body, esercizio, _.get(query, 'startDate', false), _.get(query, 'endDate', false), _.get(pageContent, 'id', false), _.get(query, 'persone', false), prezzo, tariffa)
        .then(({data}) => {
          const id = data;
          let formMessage = 'errore';
          if (!id.error) {
            window.dataLayer.push({
              event: 'contact_detail',
              id: id,
              apartment: {
                id: _.get(pageContent, 'id', false),
                name: _.get(pageContent, 'nome.testo', false),
              },
              residence: {
                id: _.get(pageContent, 'residence.id', false),
                name: _.get(pageContent, 'residence.nome', false),
              },
              from: _.get(query, 'startDate', false),
              to: _.get(query, 'endDate', false),
              persons: _.get(query, 'persone', false),
              price: prezzo,
              rate: tariffa,
            });
            formMessage = id;
            self.setState({
              isLoading: false,
              onContactSubmitValidation: formMessage,
              resetForm: true
            });
          } else {
            self.setState({
              isLoading: false,
              onContactSubmitValidation: formMessage
            });
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    }
  }

  onResetForm() {
    this.setState({
      resetForm: false
    });
  }

  getMetaContent(property, name) {
    return document.head.querySelector("[" + property + "=" + name + "]").content || '';
  }

  onPrenotaSubmit(body, errors) {
    const {esercizio, prenotazione, servizi_aggiuntivi} = this.state;

    const links = _.get(prenotazione, 'retta.pagamento._links', false) ? _.get(prenotazione, 'retta.pagamento._links') : false;

    const self = this;
    let servizi = {};

    const seed = this.getMetaContent('name', 'seed')
    if (esercizio) {
      if (links) {
        let url = '';
        // se esiste il link "prenota_diretto" uso quello, altrimenti prendo il default "prenota"
        if (links.prenota_diretto) {
          url = links.prenota_diretto.href;
        } else {
          url = links.prenota.href;
        }
        if (servizi_aggiuntivi && Object.values(servizi_aggiuntivi).length > 0) {
          Object.values(servizi_aggiuntivi).map((val, indx) => {
            if (val.isAggiunto) {
              /* if(val.giorni){
                
              } else {
                servizi[val.id] = {
                  'servizio': val.id,
                  'quantita': val.selectedMax
                };
              } */
              servizi[val.id] = {
                'servizio': val.id,
                'quantita': val.selectedMax,
                'giorni': val.selectedGiorni,
                'persone': val.selectedPersone
              };
            }
            return null;
          })
        }
        body = {
          ...body,
          servizi: servizi,
          hash: seed,
          buono_sconto: this.state.discount
        };
        this.setState({
          isLoading: true
        });
        this.appartamenti.postPrenotazione(body, url, esercizio)
          .then(({data}) => {

            if (data && data.url) {
              window.location.assign(data.url);
            } else {

              let errorMessage = __('errore');
              if (_.get(data, 'messages.main', false)) {
                errorMessage = _.get(data, 'messages.main');
              }

              self.setState({
                isLoading: false,
                onPrenotaSubmitValidation: errorMessage
              });

              window.goto(document.body, document.getElementById('completa_prenotazione').offsetTop, 600);
            }
          })
          .catch(err => {
            console.log('err', err);
          });
      } else {
        window.goto(document.body, document.getElementById('prenota').offsetTop, 600);
      }
    }
  }

  /*
  getInfoOfferta(){
    const self = this;
    const { pageContent: { rette: { 0: { offerte }}}, esercizio, info_offerta = false } = this.state;
    
    if(offerte && Object.values(offerte)[0]) {      
      const offerta = Object.values(offerte)[0];
      const href = _.get(offerta,'_links.offerta.href', '');
      if(href !== '' && !info_offerta){
        
        return (
          this.appartamenti.getInfoHref(esercizio, href)
          .then(res => {
    
            const { data } = res;
            self.setState({
              info_offerta: data
            });
          })
          .catch(error => console.log('error', error)));
      }
    }
  }
  */


  updateSearch({isLoading, prenotazione, warningDates, hasRiepilogo}) {
    this.setState({
      isLoading,
      warningDates: warningDates || this.state.warningDates,
      hasRiepilogo,
    });
    if (prenotazione || prenotazione === null) {
      this.setState({
        prenotazione,
      })
    }
  }

  updateSearchParams = () => {
    let {endDate, startDate, persone = 1, location, discount} = this.state;
    if (startDate && typeof startDate !== 'string') {
      startDate = startDate.format('YYYY-MM-DD');
    }
    if (endDate && typeof endDate !== 'string') {
      endDate = endDate.format('YYYY-MM-DD');
    }
    if (startDate && endDate) {
      let newSearch = `?startDate=${startDate}&endDate=${endDate}&persone=${persone}`;
      if (discount) {
        newSearch += `&discount=${discount}`
      }
      if (location.search !== newSearch) {
        this.setState({
          isLoading: true,
          prenotazione: null,
          cambiaDate: false,
          showUpdatePrice: false
        });
        this.props.history.push(`${location.pathname}${newSearch}`);
      } else {
        this.setState({cambiaDate: true});
      }
    } else {
      this.setState({hasRiepilogo: false, showUpdatePrice: false});
    }
  }

  onUpdatePaymentClick = (event) => {
    this.updateSearchParams()
  }

  updateDiscount = (discount) => {
    this.setState({
      discount
    }, () => {
      this.updateSearchParams()
    })
  }

  onTogglePopup = () => {
    this.setState({
      popupOspiti: !this.state.popupOspiti
    })
  }

  onCountChange = ({count}) => {
    this.setState({count_persone: count})
  }

	updatePeople = () => {
		this.setState({
      persone: this.state.count_persone,
      popupOspiti: false,
    }, () => {
      this.updateSearchParams()
    })
	}

  onDiscountCodeChange = (text) => {
    this.discount = text
  }

  onCheckDiscountClick = () => {
    this.updateDiscount(this.discount)
  }

  normalizeParams = (params) => {
    if (typeof params.persone !== 'undefined') {
      params.persone = parseInt(params.persone) || 1;
    }

    return params;
  }

  render() {

    const {
      pageContent,
      esercizio,
      query,
      servizi_aggiuntivi,
      warningDates = false,
      warningMessage = '',
      onContactSubmitValidation,
      onPrenotaSubmitValidation,
      resetForm,
      isLoading,
      startDate,
      endDate,
      noAppartamento,
      richiestaInfoChiusa,
      hasPayments,
      cambiaDate,
      popupOspiti,
      count_persone,
      activePayment,
    } = this.state;

    if (noAppartamento) {
      return <main>
        <BookingError
          match={this.props.match}
          text={{
            h1: __('Siamo spiacenti, l\'appartamento non esiste'),
            toHome: __('Clicca qui per tornare alla Home'),
            newSearch: __('Si consiglia di provare con una nuova ricerca.')
          }}
          history={this.props.history}
          location={this.state.location}
          {...{esercizio}} />
      </main>;
    }
    if (!pageContent) {
      return <main className={[styles.detail, 'min-h-100', 'main-detail'].join(' ')}><Loader/></main>;
    }
    // verifico se c'è un metodo di pagamento selezionato, altrimenti recupero il prezzo minimo tra le rette per passarlo alla bottom bar
    let {prenotazione} = this.state;
    if (!prenotazione) {
      prenotazione = [];
      const rette = _.get(pageContent, 'rette', null);
      let min_price = 99999999;
      if (rette && Object.values(rette).length > 0) {
        Object.values(rette).map((val, indx) => {
          if (_.get(val, 'prezzo', 99999999) < min_price) {
            min_price = val.prezzo;
          }
          return null;
        })
      }
      prenotazione['activePayment'] = -1;
      if (min_price < 99999999) {
        prenotazione['min_price'] = min_price;
      }
    }

    const nome = getTitoloAppartamento(pageContent, _.get(esercizio, 'configurazioni.titolo_appartamento', ''));
    const tipoApp = _.get(pageContent, 'residence.tipologia.nome', '');
    const nomeApp = _.get(pageContent, 'residence.nome', '') + ' ' + _.get(pageContent, 'nome.testo', '');
    let descrizioni = []
    for (const qualeDescrzione of _.get(esercizio, 'configurazioni.descrizioni') || []) {
      let descrizione
      if (qualeDescrzione === 'appartamento') {
        descrizione = _.get(pageContent, 'descrizione.testo')
      } else if (qualeDescrzione === 'residence') {
        descrizione = _.get(pageContent, 'residence.descrizione.testo');
      }
      if (!descrizione) {
        continue
      }
      descrizioni.push(descrizione);
    }
    const codiceIdentificativo = _.get(pageContent, 'interni[0].codice_identificativo', '');
    const servizi = pageContent.servizi || [];
    const indirizzo = _.get(pageContent, 'residence.indirizzo', '');
    const nr_civico = _.get(pageContent, 'residence.numero', '');
    const citta = _.get(pageContent, 'residence.citta.nome', '');
    const luogo = _.get(pageContent, 'residence.zona.nome', '');
    const lat = _.get(pageContent, 'residence.latitudine', '');
    const lng = _.get(pageContent, 'residence.longitudine', '');
    const orari = _.get(pageContent, 'checkin_checkout', _.get(esercizio, 'orari', ''));
    const serviziInclusi = servizi.filter(servizio => servizio.stato === 'S');
    const serviziVietati = servizi.filter(servizio => servizio.stato === 'V');
    const serviziExtra = servizi.filter(servizio => servizio.stato === 'P');
    const serviziPagamento = _.get(pageContent, 'rette[0]._links.servizi_extra.href', '');
    const notePrenotazione = _.get(esercizio, 'informazioni.noteprenotazione', '');

    const classMetodo = styles.metodo;

    // rimosso pulsante per aggiornamento tariffe - automatico alla selezione di start+end Date
    // let showClass = '';
    // if(this.state.showUpdatePrice){
    //   showClass = styles.shown;
    // }
    let search = query;
    if (!search) {
      search = parseQuery(this.state.location.search, this.normalizeParams)
    }

    let hasRette = false;
    if ((_.get(pageContent, 'rette', false) && typeof (pageContent.rette) === 'object' && pageContent.rette.length > 0) || (search.startDate && search.endDate))
      hasRette = true;

    let riepilogo = false;
    if (this.state.hasRiepilogo)
      riepilogo = true;

    let initialInfo = true;
    if (hasRette || this.state.hasRiepilogo)
      initialInfo = false;

    let showGallery = false;
    let showPanorama = false;
    let fotografie = [];
    let fotoApp = pageContent.fotografie.map(obj => ({
      ...obj,
      src: '/api/appartamenti/image/' + pageContent.residence.agenzia + '/' + pageContent.residence.id + '/' + pageContent.id + '/' + obj.id
    }));
    let fotoRes = pageContent.residence.fotografie.map(obj => ({
      ...obj,
      src: '/api/appartamenti/residences/image/' + pageContent.residence.agenzia + '/' + pageContent.residence.id + '/' + obj.id
    }));

    fotografie = fotoApp.concat(fotoRes);

    if (fotografie && fotografie.length) {
      showGallery = true;
      fotografie.map((item, index) => {
        if (item.pano) {
          showPanorama = true;
          return;
        }
      });
    }


    return (
      <main ref={this.setMainRef} className={[styles.detail, 'min-h-100'].join(' ')}>

        {warningDates &&
          <div className={[styles.warningMessage, 'secondary-background'].join(' ')}>
            <div className={styles.warningMessageInner}>
              <p className={'secondary-color'}>{warningMessage !== '' ? warningMessage : __('Le informazioni utilizzate per la ricerca non sono corrette.')}
                <span onClick={() => window.goto(document.body, document.getElementById('calendar').offsetTop, 600)} className={styles.link}>{__('Verifica la disponibilità dell\'appartamento')}</span>
                <i className={['icon-close', styles.close_icon, styles.icon].join(' ')} onClick={() => this.setState({warningDates: false})}></i>
              </p>
            </div>
          </div>
        }

        {search.startDate && search.endDate && <SearchBar
          key='search_top'
          position='top' // comanda la posizione della barra e la direzione dei popup (opposto del valore specificato)
          search={false}
          detailPage={true}
          esercizio={esercizio}
          onSearch={this.onSearch}
          match={this.state.match}
          history={this.props.history}
          location={this.state.location}
          hasRiepilogo={(search && search.startDate && search.endDate && search.persone) ? true : false}
        />}

        {showGallery &&
          <div className={styles.banner}>
            <Gallery
              key='gallery'
              id={pageContent.id}
              images={fotografie}
              paginationColor='title-color'
              options={{
                slidesPerView: 'auto',
                navigation: {
                  nextEl: `.swiper-button-next-${pageContent.id}`,
                  prevEl: `.swiper-button-prev-${pageContent.id}`,
                },
                pagination: {
                  el: `.swiper-pagination-${pageContent.id}`,
                  type: 'bullets',
                  clickable: true
                }
              }}
            />
          </div>
        }

        <div className={['w-70', 'center', styles.mainCol].join(' ')}>

          <div className={[styles.location, 'title-color'].join(' ')}>
            {nome && <h1>{nome}</h1>}

            {luogo && _.get(esercizio, 'configurazioni.mostra_mappa') &&
              <div className={[styles.luogo, 'c_pointer'].join(' ')} onClick={() => window.goto(document.body, document.getElementById('mappa').offsetTop, 600)}>
                <IconText className={['title-color', styles.luogo].join(' ')} size='big' font_weight='500' text={luogo} icon={'icon-map'}/>
              </div>
            }
            {luogo && !_.get(esercizio, 'configurazioni.mostra_mappa') &&
              <div className={styles.luogo}>
                <IconText className={['title-color', styles.luogo].join(' ')} size='big' font_weight='500' text={luogo} icon={'icon-map'}/>
              </div>
            }
          </div>

          <div className={styles.caratteristicheContainer}>
            {_.has(pageContent, 'posti_max') && <div className={styles.servizio}>
              <IconText className='title-color' size='big' font_weight='500' text={__('%s posto letto|%s posti letto', pageContent.posti_max)} icon={'icon-bed'}/>
            </div>}
            {_.has(pageContent, 'camere') && <div className={styles.servizio}>
              <IconText className='title-color' size='big' font_weight='500' text={__('%s camera|%s camere', pageContent.camere)} icon={'icon-key'}/>
            </div>}
            {_.has(pageContent, 'bagni') && <div className={styles.servizio}>
              <IconText className='title-color' size='big' font_weight='500' text={__('%s bagno|%s bagni', pageContent.bagni)} icon={'icon-108'}/>
            </div>}
            {_.has(pageContent, 'classe_energetica') && <div className={styles.servizio}>
              <IconText className='title-color' size='big' font_weight='500' text={__('Classe energetica %s', pageContent.classe_energetica)} icon={'icon-classe_energetica'}/>
            </div>}
            {_.has(pageContent, 'ipe') && _.get(pageContent, 'ipe') !== 0 && <div className={styles.servizio}>
              <IconText className='title-color' size='big' font_weight='500' text={__('IPE %s kWh/mq', pageContent.ipe)} icon={'icon-ipe'}/>
            </div>}
            {_.get(pageContent,'residence.distanze') &&
              Object.values(pageContent.residence.distanze).map((val,indx) => {
                return (
                  <IconText className='title-color' size='big' font_weight='500' text={`${val.testo}`} icon={`${val.icon}`}/>
                )
              })
            }

            {serviziInclusi && serviziInclusi.map((servizio, index) => {
                return (
                  _.includes(_.get(esercizio, 'configurazioni.servizi_principali'), servizio.servizio.codice) &&

                  <div key={index} className={styles.servizio}>
                    <IconText className='title-color'
                              size='big'
                              font_weight='500'
                              text={servizio.servizio.nome}
                              icon={'icon-s-' + servizio.servizio.codice}/>
                  </div>

                )
              }
            )}
          </div>

          <div className={styles.descrizioneContainer}>
            <div className={styles.titolo}>
              <h2><IconText text={__('Descrizione')} icon={'icon-info'} className={'title-color'}/></h2>
            </div>
            <div className={[styles.text].join(' ')}>
              {descrizioni && descrizioni.map((descrizione) => {
                  return (
                    <p>
                      {descrizione && descrizione.split('\n').map((item, key) => {
                        return <Fragment key={key}>{item}<br/></Fragment>
                      })}
                    </p>
                  )
                }
              )}
              {codiceIdentificativo && <p>{__('Codice identificativo')}: {codiceIdentificativo}</p>}
            </div>
          </div>

          {(orari !== '') &&
            <div className={styles.orariContainer}>
              <div className={[styles.titolo, 'title-color'].join(' ')}>
                <h2><IconText text={__('Orari')} icon={'icon-24h'}/></h2>
              </div>
              <div className={styles.text}>

                {orari && Object.keys(orari).map((orario, index) => {
                    return (
                      <div key={index} className={styles.singleOrario}>
                        <h3 className='text-color'>{__(orario)}</h3>
                        <p>
                          {orario && Object.keys(orari[orario]).map((val, indx) => {
                              return (
                                <span key={indx}>{__(val)} {orari[orario][val]} </span>
                              )
                            }
                          )}
                        </p>
                      </div>
                    )
                  }
                )}

              </div>
            </div>
          }

          {(serviziInclusi.length > 0) &&
            <div className={styles.serviziContainer}>
              <div className={[styles.titolo, 'title-color'].join(' ')}>
                <h2><IconText text={__('Servizi Inclusi')} icon={'icon-news'} className={'title-color'}/></h2>
              </div>
              <div className={styles.text}>
                <div className={styles.flex}>
                  {serviziInclusi && serviziInclusi.map((servizio, index) => {
                      return (
                        <div key={index} className={styles.servizio}>
                          <IconText className='title-color'
                                    textColor='text-color'
                                    text={servizio.servizio.nome}
                                    icon={'icon-s-' + servizio.servizio.codice}/>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          }

          {(serviziVietati.length > 0) &&
            <div className={styles.serviziContainer}>
              <div className={[styles.titolo, 'title-color'].join(' ')}>
                <h2><IconText text={__('Servizi Vietati')} icon={'icon-news'} className={'title-color'}/></h2>
              </div>
              <div className={styles.text}>
                <div className={styles.flex}>
                  {serviziVietati && serviziVietati.map((servizio, index) => {
                      return (
                        <div key={index} className={styles.servizio}>
                          <IconText className='title-color'
                                    textColor='text-color'
                                    text={servizio.servizio.nome}
                                    icon={'icon-s-' + servizio.servizio.codice}/>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          }

          {(serviziExtra.length > 0) &&
            <div className={styles.serviziContainer}>
              <div className={[styles.titolo, 'title-color'].join(' ')}>
                <h2><IconText text={__('Servizi Extra')} icon={'icon-star'} className={'title-color'}/></h2>
              </div>
              <div className={styles.text}>
                <div className={styles.flex}>
                  {serviziExtra && serviziExtra.map((servizio, index) => {
                      return (
                        <div key={index} className={styles.servizio}>
                          <IconText className='title-color'
                                    textColor='text-color'
                                    text={servizio.servizio.nome}
                                    icon={'icon-s-' + servizio.servizio.codice}/>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          }

          {showPanorama &&
            <div id={'panorama'} className={styles.panoramaContainer}>
              <div className={[styles.titolo, 'title-color'].join(' ')}>
                <h2><IconText text={__('Panorama')} icon={'icon-doblearr'} className={styles.rotate90}/></h2>
              </div>
              <div className={styles.panorama}>
                {fotografie.map((item) =>
                  item.pano && <Panorama image={item.src}/>
                )}
              </div>
            </div>
          }

          {_.get(esercizio, 'configurazioni.mostra_calendario') &&
            <div id={'calendar'} className={styles.calendarioContainer}>
              <div className={[styles.titolo, 'title-color'].join(' ')}>
                <h2><IconText text={__('Calendario Disponibilità')} icon={'icon-calendar'} className={'title-color'}/>
                </h2>
                {/* <div className={[styles.update_container,showClass].join(' ')}>
                  <p><b>{__('Attenzione:')}</b> {__('è necessario aggiornare le tariffe in base alle nuove date selezionate')}</p>
                  {cambiaDate && 
                    <p><b>{__('Scegli delle date diverse dalle precedenti')}</b></p>
                  }
                  <div className={styles.button_update} onClick={this.onUpdatePaymentClick} >{__('Aggiorna le Tariffe')}</div>
                </div> */}
                {/* console.log(hasPayments) */}
                {(hasPayments) &&
                  <div className={[styles.update_container, styles.shown].join(' ')}>
                    <div className={styles.button_update} onClick={() => window.goto(document.body, document.getElementById('prenota').offsetTop, 600)}>{__('Avanti')}</div>
                  </div>
                }
                {(!hasPayments) &&
                  <div className={styles.ask_info_container}>
                    <div className={styles.button_update} onClick={() => window.goto(document.body, document.getElementById('contatti').offsetTop, 600)}>{__('Richiedi informazioni')}</div>
                  </div>
                }
              </div>
              <div className={styles.text}>
                <Calendario
                  labels={false}
                  esercizio={esercizio}
                  key='calendar_middle'
                  position='middle'
                  endDate={endDate}
                  startDate={startDate}
                  match={this.state.match}
                  history={this.props.history}
                  location={this.state.location}
                  updateDates={day => this.onDateChange(day)}
                  minimumNights={this.state.minimumNights}/>
              </div>
            </div>
          }

          <div id="num-people" className={styles.peopleContainer}>
            <div className={[styles.titolo, 'title-color'].join(' ')}>
              <h2><IconText text={__('Ospiti')} icon={'icon-bed'} className={'title-color'}/></h2>
            </div>
            <div className={styles.text}>
              <div className={styles.peopleRow}>
                <span className={styles.numPeople}>{this.state.persone}</span>
                <span className={styles.peopleText}>{__('persona|persone', this.state.persone)}</span>
                <Popup
                  direction={"top"}
                  toggle={popupOspiti}
                  classe="ospitiDetail"
                  style={{marginBottom: 0}}
                  onToggle={this.onTogglePopup}
                  button={<button className={styles.editButton} onClick={this.onTogglePopup}>{__("modifica")}</button>}
                >
                  <>
                    <Counter
                      onCountChange={this.onCountChange}
                      label='persona|persone'
                      name='count_persone'
                      count={count_persone}
                      maxValue={_.get(pageContent, 'posti_max', _.get(esercizio, 'configurazioni.ospiti_massimi', 20))}
                    />
                    <div style={{flexDirection: "row", display: "flex", justifyContent: "flex-end"}}>
                      <Button
                        onClick={this.updatePeople}
                        label={__('Aggiorna')}
                        bgColor={'secondary-background'}
                        width="small"
                      />
                    </div>
                  </>
                </Popup>
              </div>
            </div>
          </div>

          {notePrenotazione &&
            <div className={styles.descrizioneContainer}>
              <div className={styles.titolo}>
                <h2><IconText text={__('Note prenotazione')} icon={'icon-info'} className={'title-color'}/></h2>
              </div>
              <div className={[styles.text].join(' ')}>
                <div dangerouslySetInnerHTML={{__html: notePrenotazione}}/>
              </div>
            </div>
          }

          {hasPayments &&
            <div id={'prenota'} className={styles.pagamentoContainer}>

              <div className={[styles.titolo, 'title-color'].join(' ')}>
                <h2><IconText text={__('Modalitá di Pagamento')} icon={'icon-bancomat'} className={'title-color'}/></h2>
                <div className={styles.penali}>
                  <div dangerouslySetInnerHTML={{__html: _.get(prenotazione, 'info.text', '')}}/>
                  {(_.get(prenotazione, 'info.text', '') === '') &&
                    (activePayment ? (
                      <p className={['align_center', styles.cta_scegli_metodo].join(' ')}>{__('Caricamento...')}</p>
                    ) : (
                      <p className={['align_center', styles.cta_scegli_metodo].join(' ')}>{__('Scegli uno dei seguenti metodi disponibili')}</p>
                    ))
                  }
                  <div className={styles.cancellazione}>
                    {(_.get(prenotazione, 'frasiPenali', false)) &&
                      <Fragment>
                        <b>{__('Cancellazioni')}</b>
                        {_.get(prenotazione, 'frasiPenali', false).map((val, indx) => {
                          const cancellazione = [_.get(val, 'disdetta.text', ''), (_.get(val, 'penale.text', '') !== '') ? _.get(val, 'penale.text') : _.get(val, 'penale_semplice.text', '')].join(' ');
                          return (
                            <div key={indx} dangerouslySetInnerHTML={{__html: cancellazione}}/>
                          )
                        })}

                      </Fragment>


                    }
                  </div>

                  {/* {_.get(prenotazione, "info_offerte.positive", []).map((val) => {
                    return (
                      <div className={styles.cancellazione}>
                        <b>{_.get(val, 'titolo.testo', '')}</b>
                        <DescrizioneOfferta>{_.get(val,'descrizione.testo', '')}</DescrizioneOfferta>
                      </div>
                    )
                  })} */}

                  {_.get(prenotazione, "info_offerte.descrittive", []).map((val) => {
                    return (
                      <div className={styles.cancellazione}>
                        <b>{_.get(val, 'titolo.testo', '')}</b>
                        <DescrizioneOfferta>{_.get(val, 'descrizione.testo', '')}</DescrizioneOfferta>
                      </div>
                    )
                  })}

                  <div className={styles.offerte}>
                    {
                      _.get(prenotazione, 'info_offerte.negative', []).length > 0 &&
                      <Fragment>
                        <h4>{__('Offerte applicate')}</h4>
                        {Object.values(_.get(prenotazione, 'info_offerte.negative', false)).map((val, indx) => {

                          let offertaTitolo = _.get(val, 'titolo.testo', '') ? _.get(val, 'titolo.testo') : '';
                          let offertaDescrizione = _.get(val, 'descrizione.testo', '') ? _.get(val, 'descrizione.testo') : '';

                          return (
                            <Fragment>
                              <p className={styles.titoloOfferte}>{offertaTitolo}</p>
                              <DescrizioneOfferta>{offertaDescrizione}</DescrizioneOfferta>
                            </Fragment>

                          )
                        })}

                      </Fragment>


                    }
                  </div>

                </div>


              </div>
              <div className={styles.text}>
                <div className={styles.rette}>
                  {pageContent.rette.map((item, index) => {
                      if (!item.pagamento) return;

                      return (
                        <MetodoPagamento
                          key={index} className={[classMetodo, 'c_pointer'].join(' ')}
                          query={query}
                          active={activePayment === item.pagamento.id}
                          onClick={this.onPaymentClick}
                          onLoad={this.onPaymentLoaded}
                          esercizio={esercizio}
                          item={item}
                        />
                      )
                    }
                  )}
                </div>
                <div style={{display: 'flex', alignItems: 'flex-start'}}>
                  <TextInput
                    defaultValue={this.state.discount}
                    name="codsconto"
                    label="Codice sconto"
                    maxWidth={200}
                    onChange={(e) => {
                      this.onDiscountCodeChange(e.target.value)
                    }}
                    error={this.state.discountError}
                  />
                  <Button label="Applica" bgColor={'secondary-background'} width="auto" style={{
                    marginLeft: 20,
                    marginBottom: 8,
                    marginTop: 15
                  }} onClick={this.onCheckDiscountClick}/>
                  <div style={{marginLeft: 20, fontSize: 30, alignSelf: 'center'}}>
                    {this.state.discountValid && <div style={{color: "#69c95b"}}>✓</div>}
                    {this.state.discountInvalid && <div style={{color: '#b42e2e'}}>✘</div>}
                  </div>
                </div>
              </div>

              <ServiziAggiuntivi
                // className={[classExtra].join(' ')}
                onChangeServizi={this.onChangeServizi}
                href={serviziPagamento}
                esercizio={esercizio}
              />

              <div id='completa_prenotazione' className={[styles.titolo, 'title-color'].join(' ')}>
                <h2><IconText text={__('Completa la prenotazione')} icon={'icon-prices'} className={'title-color'}/>
                </h2>
                {(onPrenotaSubmitValidation) &&
                  <p className={['align_center', 'color-error', styles.dinamicMessage].join(' ')}>{onPrenotaSubmitValidation}</p>
                }
              </div>
              <div className={styles.formPrenota}>
                {query && query.startDate && query.endDate && query.persone && pageContent.rette &&
                  <Form
                    // commentata per rimuovere i campi della carta di credito (usati solo per la "validazione carta mercurio")
                    // prenotaConCarta={_.get(prenotazione,'retta.pagamento.tipo','').toLowerCase().includes('carta')}
                    lang={esercizio.lang}
                    fields={fieldsBooking}
                    privacy={privacy_fieldsBooking}
                    onSubmit={this.onPrenotaSubmit}
                    validationFields={esercizio.configurazioni.prenotazione}
                    legals={esercizio.configurazioni.legals}
                    buttonLabel={__('Prenota Ora')}/>
                }
              </div>
            </div>
          }

          {(_.get(esercizio, 'configurazioni.mostra_mappa') && lat !== '' && lng !== '') &&
            <div id={'mappa'} className={styles.mappaContainer}>
              <div className={[styles.titolo, 'title-color'].join(' ')}>
                <h2><IconText text={__('Mappa')} icon={'icon-map'}/></h2>
                <div className={styles.locazione}>
                  <p><b>{nome}</b></p>
                  <p>{indirizzo}, {nr_civico}</p>
                  <p>{citta} - {luogo}</p>
                  {/*<p>GPS: {lat} - {lng}</p>*/}
                </div>
              </div>
              <div className={styles.mappa}>
                <OSMap
                  markerPosition={{
                    lat: lat,
                    lng: lng,
                    zoom: 12
                  }}
                  nome={nome}
                />
              </div>
            </div>
          }

          <div id={'contatti'} className={styles.formContainer}>
            <div className={[styles.titolo, 'title-color'].join(' ')}>
              <h2><IconText text={__('Richiedi informazioni')} icon={'icon-info'}/></h2>
              {(onContactSubmitValidation === 'errore') &&
                <p className={['align_center', 'color-error', styles.dinamicMessage].join(' ')}>{__(onContactSubmitValidation)}</p>
              }
              {(onContactSubmitValidation !== 'errore' && onContactSubmitValidation) &&
                <p className={['align_center', 'color-success', styles.dinamicMessage].join(' ')}>{__('Richiesta inviata con successo.') + ' (ID:' + onContactSubmitValidation + ')'}</p>
              }
            </div>
            <div className={[styles.text, styles.richiestaInfoText, richiestaInfoChiusa ? styles.max_h_0 : ''].join(' ')}>
              {richiestaInfoChiusa &&
                <h4 className={'align_center'} onClick={() => this.setState({richiestaInfoChiusa: !richiestaInfoChiusa})}>{__('Clicca qui per inviarci una semplice richiesta di contatto')}</h4>
              }
              <Form
                resetForm={resetForm}
                onResetForm={this.onResetForm}
                lang={esercizio.lang}
                fields={fieldsInformation}
                privacy={privacy_fieldsInformation}
                onSubmit={this.onContactSubmit}
                validationFields={esercizio.configurazioni.informazioni}
                legals={esercizio.configurazioni.legals}
                buttonLabel={__('Invia richiesta')}/>
            </div>
          </div>

        </div>

        {isLoading &&
          <Loader/>
        }

        <SearchBar
          ref={this.setBottombarRef}
          key='search_bottom'
          position='bottom'
          search={false}
          detailPage={true}
          esercizio={esercizio}
          prenotazione={prenotazione}
          servizi={servizi_aggiuntivi}
          match={this.state.match}
          history={this.props.history}
          location={this.props.location}
          hasRette={hasRette}
          hasRiepilogo={riepilogo}
          hasPayments={hasPayments}
          initialinfoOpen={initialInfo}
          calendario={true}
          updateSearch={this.updateSearch}
          warningDates={this.state.warningDates}
          appartmentType={tipoApp}
          appartmentName={nomeApp}
        />

        <Modal
          isOpen={this.state.paymentErrorModal}
          onRequestClose={() => this.setState({paymentErrorModal: false})}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              display: "flex",
              flexDirection: "column",
              maxWidth: 700,
              minWidth: 350,
              maxHeight: "90%",
              overflowY: "scroll",
            }
          }}
        >
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div className="color-error" style={{fontSize: 24, flex: 1}}>Prenotazione non completata</div>
            <div style={{
              alignSelf: 'flex-end',
              marginBottom: 10
            }} onClick={() => this.setState({paymentErrorModal: false})}>
              <i className="icon-close"/>
            </div>
          </div>
          <div style={{marginTop: 20}}>Non è stato possibile eseguire il pagamento, pertanto la prenotazione non è stata completata.</div>
        </Modal>

      </main>
    )
  }
}