import React, {Fragment} from 'react';
import { __ } from './../../utils/translator';
import style from './style.module.css';
import IconText from '../../components/IconText';
import BookingError from './../../components/Errore';
import Appartamenti from '../../services/appartamenti';
import { parseQuery, getDistanze, getTitoloAppartamento } from './../../utils';
import _ from 'lodash';
import moment from 'moment';

export default class Confirmation extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      warningDates: true,
      ...props
    }

  }

  componentDidMount() {
    const self = this;
    const { 
      esercizio,
      location: { search },
      match: {
        params: { seed, check }
      }
    } = this.state;

    this.appartamenti = new Appartamenti();
    const query  = parseQuery(search);
    
    const idPrenotazione = _.get(query,'__id',false) ? _.get(query,'__id') : _.get(query,'__idprenotazione');

    if(idPrenotazione) {
      this.appartamenti.getPrenotazione(esercizio, idPrenotazione, seed, check)
        .then(({ data }) => {
          if (!data.error) {
            let pageContent = data;
            const { residence: { distanze } } = pageContent;
            pageContent.residence['distanze'] = getDistanze(distanze);

            self.setState({ pageContent: pageContent });
            window.imgContainer();

            const urlFrasi = _.get(pageContent,'pagamento_prenotazione._links.frasi.href',false);
            // // effettuo la chiamata per recuperare ulteriori informazioni 
            if(urlFrasi){
              self.appartamenti.getInfoHref(esercizio, urlFrasi)
              .then(({ data }) => {
                self.setState({ frasi: data });
              })
              .catch(err => {
                console.log('err', err);
              });
            }

            window.dataLayer.push({
              event: 'reservation',
              id: idPrenotazione,
              number: _.get(pageContent,'nord',false),
              apartment: {
                id: _.get(pageContent.appartamento,'id',false),
                name: _.get(pageContent.appartamento,'nome.testo',false),
              },
              residence: {
                id: _.get(pageContent.residence,'id',false),
                name: _.get(pageContent.residence,'nome',false),
              },
              from: _.get(pageContent,'arrivo',false),
              to: _.get(pageContent,'partenza',false),
              persons: _.get(pageContent,'persone',false),
              price: _.get(pageContent,'prezzo',false),
              services: _.get(pageContent,'servizi',[]),
            });

            setTimeout(function(){
              window.imgContainer();
            },500);
          } else {
            self.setState({ pageContent: '' });
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    } else {
      self.setState({ pageContent: '' });      
    }
  }

  render(){
    const { esercizio, match, pageContent, frasi, warningDates } = this.state;

    if (!match.params.seed || !match.params.check || pageContent === '') {
      return <main className={[style.confirm].join(' ')} >
              <BookingError 
                match={match}
                text={{
                  h1: __('Siamo spiacenti, la prenotazione non è andata a buon fine'),
                  toHome: __('Clicca qui per tornare alla Home'),
                  newSearch: __('Si consiglia di provare con una nuova ricerca.')
                }}
                history={this.props.history}
                location={this.state.location}
                {...{esercizio}} />
              </main>;
    }
    if (!pageContent || !esercizio) {
      return <main className={[style.confirm].join(' ')}>
        <div className={'loader'}><div className='spinner'><div></div><div></div></div></div>
      </main>; 
    }

    const dataAppartamento= {
      ...pageContent.appartamento,
      residence: pageContent.residence
    }

    const nome = getTitoloAppartamento(dataAppartamento, _.get(esercizio,'configurazioni.titolo_appartamento', ''));
    const descrizione1 = _.get(pageContent, 'appartamento.descrizione.testo');
    const descrizione2 = _.get(pageContent, 'residence.descrizione.testo');     
    const servizi_appartamento = _.get(pageContent,'appartamento.servizi',[]);
    const servizi_prenotazione = _.get(pageContent,'servizi',[]);
    const luogo = _.get(pageContent,'residence.indirizzo','') +', '+ _.get(pageContent,'residence.numero','') +' - '+ _.get(pageContent,'residence.citta.nome','') +' - '+ _.get(pageContent,'residence.zona.nome','');
    const orari = _.get(pageContent, 'checkin_checkout', _.get(esercizio, 'orari', ''));
    const arrivo = moment(_.get(pageContent,'arrivo',false)).format('DD-MM-YYYY');
    const partenza = moment(_.get(pageContent,'partenza',false)).format('DD-MM-YYYY');
    const cliente = _.get(pageContent,'cliente.nome','') +' '+ _.get(pageContent,'cliente.cognome','');
    const email = _.get(pageContent,'cliente.email',false);
    const tipo_pagamento = _.get(frasi,'frasi_pagamento.nome.text',false);
    const nord = _.get(pageContent,'nord',false);

    const prezzo = _.get(pageContent,'prezzo',false);
    const prezzo_totale = _.get(pageContent,'prezzo_totale',false);
    const prezzo_pagato = _.get(pageContent,'prezzo_pagato',false);
    const giorni_anticipo_saldo = _.get(pageContent,'giorni_anticipo_saldo',0);

    let img = _.get(pageContent, 'appartamento.fotografie[0].id', false);
    if (img !== false) {
      img = pageContent.residence.agenzia + '/' + pageContent.residence.id + '/' + pageContent.id + '/' + img;
    }
    
    return(
      <main className={[style.confirm]}>
        
        {warningDates &&
        <div className={[style.warningMessage,'secondary-background'].join(' ')}>
          <div className={style.warningMessageInner}>
            <p className={'secondary-color'}>{__('Congratulazioni, la prenotazione è stata eseguita correttamente!')} <i className={['icon-close',style.close_icon,style.icon].join(' ')} onClick={() => this.setState({ warningDates: false })}></i></p>
          </div>
        </div>
        }

        <div className={['w-70', 'center',style.mainCol].join(' ')}>
          

          <div className={[style.dettaglioPrezzi,style.fascia_container].join(' ')}>
            
            {img &&
            <div className={[style.titolo,style.imgContainerOuter].join(' ')}>
              <div className={['img-container','relative',style.imgContainer].join(' ')}>
                <img src={`/api/appartamenti/image/${img}`} className={'ww'} />
              </div>
            </div>
            }
            
            <div className={[style.text,style.containerTitolo].join(' ')}>

              <div className={[style.location, 'title-color'].join(' ')}>
                <p className={style.italic}>{__('Prenotazione confermata per:')}</p>
                {nome && <h1>{nome}</h1>}
                <div className={style.luogo} ><IconText className={['title-color',style.luogo].join(' ')} size='big' font_weight='500' text={luogo} icon={'icon-map'} /></div> 
              </div>
            </div>
          </div>


          <div className={[style.riepilogoPrenotazione,style.fascia_container].join(' ')}>
            <div className={style.titolo}>
              <h2><IconText text={__('Riepilogo Prenotazione')} icon={'icon-prices'} className={'title-color'} /></h2>
            </div>
            <div className={[style.text].join(' ')}>
            
              {arrivo && 
              <div className={style.singleEl}>
                <h4 className='text-color'>{__('Arrivo')}</h4>
                  <p> 
                    <span> {arrivo}</span>
                  </p>  
                </div>
              }
              {partenza && 
              <div className={style.singleEl}>
                <h4 className='text-color'>{__('Partenza')}</h4>
                  <p> 
                    <span> {partenza}</span>
                  </p>  
                </div>
              }
              {cliente && 
              <div className={style.singleEl}>
                <h4 className='text-color'>{__('Cliente')}</h4>
                  <p> 
                    <span> {cliente}</span>
                  </p>  
                </div>
              }
              {email && 
              <div className={style.singleEl}>
                <h4 className='text-color'>{__('Email')}</h4>
                  <p> 
                    <span> {email}</span>
                  </p>  
                </div>
              }
              {tipo_pagamento && 
              <div className={style.singleEl}>
                <h4 className='text-color'>{__('Metodo di Pagamento')}</h4>
                  <p> 
                    <span> {tipo_pagamento}</span>
                  </p>  
                </div>
              }
              {nord && 
              <div className={style.singleEl}>
                <h4 className='text-color'>{__('Numero Ordine')}</h4>
                  <p> 
                    <span> {nord}</span>
                  </p>  
                </div>
              }
              
            </div>
          </div>

          {(orari !== '') && 
          <div className={[style.orariContainer,style.fascia_container].join(' ')}>
            <div className={[style.titolo, 'title-color'].join(' ')}>
              <h2><IconText text={__('Orari')} icon={'icon-24h'} /></h2>
            </div>
            <div className={style.text}>


            {orari && Object.keys(orari).map((orario, index) => 
                {
                  return (
                  <div key={index} className={style.singleOrario}>
                  <h3 className='text-color'>{__(orario)}</h3>
                  <p>
                  {orario && Object.keys(orari[orario]).map((val, indx) => 
                    { return(
                      <span key={indx}>{__(val)} {orari[orario][val]} </span>
                    )}
                    
                  )}
                  </p>
                  </div>
                  )
                }
            )}
            </div>
          </div> }

          <div className={[style.dettaglioPrezzi,style.fascia_container].join(' ')}>
            <div className={style.titolo}>
              <h2><IconText text={__('Dettaglio Prezzi')} icon={'icon-bancomat'} className={'title-color'} /></h2>
            </div>
            
            <div className={[style.text].join(' ')}>
              {(prezzo) &&
                <div className={[style.prezzo,style.singleLine].join(' ')}>
                  <h4>{__('Prezzo Finale')}</h4>
                  <p>€ {parseFloat(prezzo).toFixed(2)}</p>
                </div>
              }
              {(servizi_prenotazione && Object.values(servizi_prenotazione).length > 0 ) &&
                <div className={style.prezzo}>
                  <h4>{__('Servizi aggiuntivi')}</h4>
                  {Object.values(servizi_prenotazione).map((val, indx) => 
                  { 
                    return(
                      <p key={indx}>
                        <span><b>{_.get(val,'servizio.titolo.testo','')}:</b><br/>
                        {(_.get(val,'servizio.per_appartamento','') === 'persona') &&  __('%s persona|%s persone',val.quantita) +' X ' }
                        {_.get(val,'giorni','') && __('%s giorno|%s giorni',val.giorni)}</span> 
                        <span>€ {parseFloat(val.prezzo).toFixed(2)}</span>
                      </p>   
                    )                    
                  })}
                </div>
              }
              {(prezzo_totale && prezzo_pagato) &&
                <Fragment>
                  <div className={[style.prezzo].join(' ')}>
                    <p><span>{__('mezzo:') +' '+ tipo_pagamento}</span> <span>€ {parseFloat(prezzo_pagato).toFixed(2)}</span></p>
                    <p>
                      {!giorni_anticipo_saldo &&
                        <span>{__("da pagare all'arrivo")}</span>
                      }
                      {giorni_anticipo_saldo > 0 &&
                        <span>{__("da pagare entro %s giorno dall'arrivo|da pagare entro %s giorni dall'arrivo",giorni_anticipo_saldo)}</span>
                      }
                      <Fragment> </Fragment>
                      <span>€ {parseFloat(prezzo_totale - prezzo_pagato).toFixed(2)}</span>
                    </p>
                  </div>
                  <div className={[style.prezzo,style.singleLine].join(' ')}>
                    <h3>{__('Totale')}</h3>
                    <p>€ {parseFloat(prezzo_totale).toFixed(2)}</p>
                  </div>
                </Fragment>
              }
            </div>
            
            <div className={style.titolo}></div>           

            <div className={[style.text,style.cancellazioni].join(' ')}>
              <h5>{__('Cancellazioni')}</h5>
                {Object.values(_.get(frasi,'frasi_penali', false)).map((val,indx)=>{
                  const cancellazione = ['<p>', _.get(val,'disdetta.text', '') , (_.get(val,'penale.text','') !== '') ? _.get(val,'penale.text') : _.get(val,'penale_semplice.text','') ,'</p>' ].join(' ');
                  return (
                    <div key={indx} dangerouslySetInnerHTML={{ __html:  cancellazione }} />
                  )
                })}
            </div>
          </div>

          <div className={[style.descrizioneContainer,style.fascia_container].join(' ')}>
            <div className={style.titolo}>
              <h2><IconText text={__('Descrizione')} icon={'icon-info'} className={'title-color'} /></h2>
            </div>
            <div className={[style.text].join(' ')}>
              {descrizione1 && <p>{descrizione1.split('\n').map((item, key) => {
                return <Fragment key={key}>{item}<br/></Fragment>
              })}</p>}
              {descrizione2 && <p>{descrizione2.split('\n').map((item, key) => {
                return <Fragment key={key}>{item}<br/></Fragment>
              })}</p>}
            </div>
          </div>

          <div className={[style.caratteristicheContainer,style.fascia_container].join(' ')}>

            {_.get(pageContent,'appartamento.posti_max') && <div className={style.servizio}><IconText className='title-color' size='big' font_weight='500' text={__('%s posto letto|%s posti letto',pageContent.appartamento.posti_max)} icon={'icon-bed'} /></div> }
            {(_.get(pageContent,'appartamento.camere') != 0) && <div className={style.servizio}><IconText className='title-color' size='big' font_weight='500' text={__('%s camera|%s camere',pageContent.appartamento.camere)} icon={'icon-key'} /></div> }
            {_.get(pageContent,'appartamento.bagni') && <div className={style.servizio}><IconText className='title-color' size='big' font_weight='500' text={__('%s bagno|%s bagni',pageContent.appartamento.bagni)} icon={'icon-108'} /></div> }
            {_.get(pageContent,'residence.distanze') && 
              Object.values(pageContent.residence.distanze).map((val,indx) => {
                return (
                  <div key={indx} className={style.servizio}><IconText className='title-color' size='big' font_weight='500' text={val.testo} icon={val.icon} /></div>
                )
              })
            }

            {servizi_appartamento && servizi_appartamento.map((servizio, index) => {
              return (
                _.includes(_.get(esercizio,'configurazioni.servizi_principali'),servizio.servizio.codice) &&      
                     
                <div key={index} className={style.servizio}>
                  <IconText className='title-color' 
                  size='big'
                  font_weight='500'
                  text={servizio.servizio.nome} 
                  icon={'icon-s-'+servizio.servizio.codice} />
                </div>
              )}
            )}
          </div>

        </div>
      </main>
    )
  }
}
